<template>
  <div>
    <section class="half" id="hospital">
      <div>
        <!-- 旗下医院标题 -->
        <h2>{{ $t("index.Our Medical Institutions") }}</h2>
        <!-- 旗下医院 -->
        <ul>
          <!-- <li
            v-for="item in hospitalsLists"
            :key="item"
            @mouseover="mousover(item)"
            :class="{ hospitalactive: item.show === true }"
          >
            <a :href="item.href">{{ item.name }}</a>
          </li> -->
          <li
            v-for="(item, index) in hospitalsLists.slice(0, 12)"
            :key="index"
            @mouseover="mousover(item)"
            :class="item.show ? 'hospitalactive' : ''"
            :id="item.id"
          >
            <!-- {{item.jumpUrl}} -->
            <a @click="hoverHospital(item)">
              {{ filterLang(item, "Name") }}
              <!-- {{item.frontSort==index+1? filterLang(item, 'Name') }} -->
            </a>
          </li>
        </ul>
        <a href="/hospitals" class="btn">{{ $t("index.Learn more") }}</a>
      </div>
      <!-- 右侧图片 -->
      <div class="Right_img">
        <div>
          <a :href="hospitalsJumpUrl">
            <span>
              [{{ filterLang(nowHospital, "OrgName") }}]
              <br />
              {{ filterLang(nowHospital, "Name") }}
            </span>
            <img
              :src="nowHospital.img"
              :alt="nowHospital.name"
              loading="lazy"
            />
          </a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { HospitalList } from "@/api/Qixiahospitals";
import { filterLang } from "@/utils/LangChange";
import { getAction } from "@/api/manage";

export default {
  data() {
    return {
      hospitalsJumpUrl: "",
      hospitalsLists: [],
      // 当前医院
      nowHospital: {},
    };
  },
  created() {
    getAction("/jeecg-system/frontend/qixiaHospital/list_all", {
      sortAsc: false,
    }).then((res) => {
      this.hospitalsLists = res.result;
      this.hospitalsLists.map((res, index) => {
        if (index == 0) {
          res.show = true;
          this.nowHospital = res;
        } else {
          res.show = false;
        }
      });
      // 排序
      this.hospitalsLists.sort(this.sortby("frontSort"));
    });
    // 把当前选中的医院对象加入 images
    this.nowHospital = this.hospitalsLists.filter((res) => res.show === true);
    console.log(this.hospitalsLists);
    // 默认第一个医院
    // this.mousover(this.hospitalsLists);
    this.hospitalsLists[0].show = true;
    // console.log(this.hospitalsLists[0]);
  },
  methods: {
    //排序
    sortby(prop, rev = true) {
      // prop 属性名
      // rev  升序降序 默认升序
      return function (a, b) {
        var val1 = a[prop];
        var val2 = b[prop];
        return rev ? val1 - val2 : val2 - val1;
      };
    },
    hoverHospital(item) {
      window.open(item.jumpUrl, "_blank");
    },
    filterLang,
    // 鼠标移入
    mousover(item) {
      this.hospitalsJumpUrl = item.jumpUrl;
      // 把所有的img显示清除
      this.hospitalsLists.map((res) => {
        if (res.show === true) {
          res.show = false;
        }
        // console.log(res.show);
        if (res.id === item.id) {
          res.show = true;
          this.nowHospital = res;
        }
      });
    },
  },
};
</script>

<style scoped lang='less'>
.hospitalactive {
  color: #e60044;
  border-color: #e60044 !important;
}
section h2 {
  font-size: 1.6rem;
  color: #595757;
}
section h2:after {
  margin-top: 1em;
  width: 2em;
  height: 0.1em;
  content: "";
  background-color: #c81a41;
  display: block;
}
a.btn {
  margin-top: 2em;
  padding: 0.8em;
  line-height: 1;
  text-align: center;
  display: inline-block;
  border: 2px solid #19599a;
  border-radius: 2em;
  color: #595757;
}

a.btn:hover {
  color: #e60044;
  border-color: #e60044;
}
/* 右侧医院图片 */
.half {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.half > div:nth-of-type(1) {
  width: 50%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
}

.half > div + div {
  width: 46%;
  height: 406px;
  background-color: #fff;
  box-shadow: #ddd 0 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* 右侧医院图片文字 */
.Right_img > div {
  width: 100%;
  display: block;
  position: relative;
}

.Right_img div span {
  position: absolute;
  left: 1em;
  bottom: 1em;
  font-size: 2rem;
  color: #fff;
}
.half img {
  max-width: 703px;
  width: 100%;
  max-height: 482px;
  display: block;
}

.half p {
  line-height: 2;
  width: 100%;
  color: #595757;
}

.half p.hp1 {
  /*margin-top: -4em;*/
}

.half ul {
  margin-top: 1em;
  width: 100%;
  list-style: none;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: hidden;
}

.half ul {
  margin-top: 1em;
  width: 100%;
  list-style: none;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: hidden;
  box-sizing: border-box;
}
.half li {
  margin-top: 1em;
  padding: 0.5em 0;
  width: 49%;
  border: #313131 2px solid;
  text-align: center;
  color: #595757;
  font-size: 0.9em;
  box-sizing: border-box;
}

.half div:first-child ul a {
  margin-top: 1em;
  padding: 0.5em 0;
  width: 49%;
  // border: #313131 2px solid;
  text-align: center;
  color: #595757;
  font-size: 0.9em;
}
.half div:first-child ul li:hover {
  border-color: #c81a41;
  cursor: pointer;
  > a {
    color: red !important;
  }
}
@media screen and (max-width: 480px) {
  .half > div:nth-of-type(1),
  .half > div:nth-of-type(2) {
    width: 100%;
  }

  .half > div:nth-of-type(2) {
    margin-top: 1em;
  }

  .half p.hp1 {
    margin-top: 2em;
  }
}
@media screen and (max-width: 768px) {
  .half > div:nth-of-type(1),
  .half > div:nth-of-type(2) {
    width: 100%;
  }
  #hospital {
    flex-direction: column;
  }
  section > * {
    flex-basis: 100% !important;
  }
  section h2 + * {
    margin-top: 2em;
    font-size: 0.8rem;
  }

  .half > div:nth-of-type(1),
  .half > div:nth-of-type(2) {
    width: 100% !important;
  }

  .half > div:nth-of-type(2) {
    margin-top: 1em;
  }

  .half p.hp1 {
    margin-top: 2em;
  }
  section h2 {
    font-size: 1.5rem;
  }
  section a.btn {
    margin: 1em auto 0 auto;
    padding: 0.5em 1em;
    width: 10em;
    font-size: 0.8rem;
    text-align: center;
    display: block;
  }
}
@media screen and (max-width: 1500px) {
  .half > div:nth-of-type(1) {
    width: 50%;
  }

  .half > div + div {
    height: 29vw;
  }
  section {
    margin-top: 2em;
    padding: 1em 0.5em;
  }
}
</style>