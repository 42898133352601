<template>
  <div>
    <!-- 轮播图 -->
    <indexbanner></indexbanner>
    <div v-for="item in sortList" :key="item.homePageModuleId">
      <!-- 关于锦欣 -->
      <AboutUs
        v-if="item.homePageModuleName == '关于锦欣'"
        :DataList="DataList.aboutjx"
      ></AboutUs>
      <!-- 旗下医院 -->
      <Hospitals v-if="item.homePageModuleName == '旗下医院'"></Hospitals>
      <!-- 新闻中心 -->
      <NewsCenter
        v-if="item.homePageModuleName == '新闻中心'"
        :DataList="DataList.News"
      ></NewsCenter>
      <!-- 人才招聘 -->
      <FindJob
        v-if="item.homePageModuleName == '人才招聘'"
        :DataList="DataList.rencai"
      ></FindJob>
      <!-- 宣传视频 -->
      <publicity-video
        v-if="item.homePageModuleName == '宣传视频'"
        :videoUrl="DataList.video"
        style="margin-top: 5em"
      ></publicity-video>
    </div>
  </div>
</template>

<script>
import indexbanner from "../components/indexContent/indexbanner.vue";
import FindJob from "@/components/indexContent/FindJob.vue";
import NewsCenter from "@/components/indexContent/NewsCenter.vue";
import Hospitals from "@/components/indexContent/Hospitals.vue";
import AboutUs from "@/components/indexContent/AboutUs.vue";
import publicityVideo from "@/components/indexContent/video.vue";
import { getAction } from "@/api/manage";

export default {
  name: "Home",
  data() {
    return {
      bannerNum: 0,
      screenWidth: 0,
      hospitalsList: [],
      // 当前医院
      nowHospital: {},
      url: {
        sort: "/jeecg-system/frontend/home_page_module/list_content",
        articleType: "/jeecg-system/frontend/articleType/list_all",
        article: "/jeecg-system/common/frontend/article/active_list",
      },
      sortList: [],
      // EN: {
      //   aboutjx: {},
      //   rencai: {},
      // },
      // sample: {
      //   aboutjx: {},
      //   rencai: {},
      // },
      // tranditional: {
      //   aboutjx: {},
      //   rencai: {},
      // },
      DataList: {
        aboutjx: {},
        rencai: {},
        video: "",
        News: [],
      },
    };
  },
  created() {
    this.hospitalsList = this.$t("index.hospitalsList");
    this.screenWidth = document.documentElement.clientWidth;
    // 把当前选中的医院对象加入 images
    this.images = this.hospitalsList.filter((res) => res.show === true);
    // console.log(this.images);
    this.mousover(this.hospitalsList[0]);
    this.getSortList();
    this.getIndexList();
     if (this.$store.state.nowlanguage == "zh") {
        this.getIndexList("sample");
      }
      if (this.$store.state.nowlanguage == "tr") {
        this.getIndexList("tranditional");
      }
      if (this.$store.state.nowlanguage == "en") {
        this.getIndexList("EN");
      }
  },

  methods: {
    // 获取排序
    async getSortList() {
      const { result, success } = await getAction(this.url.sort, {
        langugeType: "chinese",
      });
      this.sortList = result;
    },
    // 鼠标移入
    mousover(item) {
      // 把所有的img显示清除
      // eslint-disable-next-line guard-for-in
      this.hospitalsList.map((res) => {
        if (res.show === true) {
          res.show = false;
        }
        // console.log(res.show);
        if (res.id === item.id) {
          res.show = true;
          this.nowHospital = res;
        }
      });
    },
    getIndexList(language) {
      getAction(this.url.sort, {
        langugeType: language,
      }).then((res) => {
        console.log(res);
        for (let i of res.result) {
          if (i.homePageModuleName == "关于锦欣") {
            this.DataList.aboutjx = i;
          }
          if (i.homePageModuleName == "人才招聘") {
            this.DataList.rencai = i;
          }
          if (i.homePageModuleName == "宣传视频") {
            console.log(i.list[0].propagandaVideo);
            this.DataList.video = i.list[0].propagandaVideo;
          }
        }
      });
      // getAction("article", { typeId: 2, languageType: language }).then(
      //   (item) => {
      //     console.log(item);
      //     this.DataList.News = item.result;
      //   }
      // );
      getAction(this.url.articleType).then((res) => {
        console.log(res);
        for (let i of res.result) {
          if (i.id == 1) {
            getAction(this.url.article, {
              typeId: i.id,
              languageType: language,
            }).then((item) => {
              console.log(item);
              this.DataList.News = item.result.records;
            });
          }
        }
      });
    },
  },
  components: {
    indexbanner,
    FindJob,
    NewsCenter,
    Hospitals,
    AboutUs,
    publicityVideo,
  },
  computed: {
    vuexData() {
      return this.$store.state.nowlanguage;
    },
  },
  watch: {
    vuexData(newVal, oldVal) {
      if (newVal == "zh") {
        this.getIndexList("sample");
        this.$router.push('/')
      }
      if (newVal == "tr") {
        this.getIndexList("tranditional");
      }
      if (newVal == "en") {
        this.getIndexList("EN");
      }
    },
  },
};
</script>

<style scoped lang=less >
header + a img {
  max-width: 100%;
  width: 100%;
}
.hospitalactive {
  border: 2px solid red !important;
}
section {
  margin: 4em auto 0 auto;
  padding: 2em 0;
  width: 100%;
  max-width: 1200px;
}

section h2 {
  font-size: 1.6rem;
  color: #595757;
}

section h2:after {
  margin-top: 1em;
  width: 2em;
  height: 0.1em;
  content: "";
  background-color: #c81a41;
  display: block;
}

/* 右侧医院图片 */
.half {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.half > div:nth-of-type(1) {
  width: 50%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
}

.half > div + div {
  width: 46%;
  height: 406px;
  background-color: #fff;
  box-shadow: #ddd 0 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* 右侧医院图片文字 */
.Right_img > div {
  width: 100%;
  display: block;
  position: relative;
}

.Right_img div span {
  position: absolute;
  left: 1em;
  bottom: 1em;
  font-size: 2rem;
  color: #fff;
}

.half img {
  max-width: 703px;
  width: 100%;
  max-height: 482px;
  display: block;
}

.half p {
  line-height: 2;
  width: 100%;
  color: #595757;
}

.half p.hp1 {
  /*margin-top: -4em;*/
}

.half ul {
  margin-top: 1em;
  width: 100%;
  list-style: none;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: hidden;
}

.half ul.hu1 {
  /*margin-top: -4em;
            */
}

.half div:first-child ul a {
  margin-top: 1em;
  padding: 0.5em 0;
  width: 49%;
  border: #313131 2px solid;
  text-align: center;
  color: #595757;
  font-size: 0.9em;
}
.half div:first-child ul a:hover {
  > li {
    color: red !important;
  }
}
/* .half li:hover {
  border: 2px solid red;
} */

.half li.c {
  border-color: #c81a41;
}

#hospital img {
  margin: 0 auto;
  display: block;
}

.recruitment {
  max-width: 1903px;
  background-color: #eee;
}

.recruitment > div {
  padding-top: 4em;
  padding-bottom: 4em;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.recruitment h2 {
  text-align: center;
  font-size: 1.6rem;
  color: #595757;
}

.recruitment h2:after {
  margin: 1em auto 0 auto;
}

.recruitment p {
  margin: 2em auto 0 auto;
  width: 100%;
  max-width: 800px;
  text-align: center;
  line-height: 2;
  font-size: 0.9rem;
}

.recruitment img {
  margin: 0 auto;
  width: 100%;
  max-width: 1500px;
  display: block;
  transform: translateY(4em);
}

.recruitment a.btn {
  margin: 2em auto 0 auto;
  width: 10em;
  display: block;
}

.recruitment video {
  margin: 0 auto;
  width: 100%;
  max-width: 1500px;
  display: block;
  transform: translateY(4em);
}

@media screen and (max-width: 1500px) {
  .half > div:nth-of-type(1) {
    width: 50%;
  }

  .half > div + div {
    height: 29vw;
  }

  section {
    margin-top: 2em;
    padding: 1em 0.5em;
    box-sizing: border-box;
  }

  figure {
    margin-top: 2em;
    padding: 1em 0.5em;
    box-sizing: border-box;
  }

  figure > div a div {
    width: 100%;
    height: 22vw;
  }

  figure > div a img {
    max-height: 22vw;
  }
}

@media screen and (max-width: 768px) {
  .banner ul {
    bottom: 1em;
  }

  .banner ul li {
    width: 2rem;
    height: 1px;
  }

  .banner ul li + li {
    margin-left: 0.2em;
  }

  .banner .lctr,
  .banner .rctr {
    display: none;
  }

  section {
    flex-direction: column;
  }

  section h2 {
    font-size: 1.5rem;
  }

  section > * {
    flex-basis: 100%;
  }

  section h2 + * {
    margin-top: 2em;
    font-size: 0.8rem;
  }

  section a.btn {
    margin: 1em auto 0 auto;
    padding: 0.5em 1em;
    width: 10em;
    font-size: 0.8rem;
    text-align: center;
    display: block;
  }

  .hospital > a {
    padding: 0;
    width: 100%;
    text-align: center;
  }

  .hospital > a + a {
    margin-top: 2em;
  }

  .hospital > a img {
    width: 100%;
    max-height: inherit;
  }

  .half > div:nth-of-type(1),
  .half > div:nth-of-type(2) {
    width: 100%;
  }

  .half > div:nth-of-type(2) {
    margin-top: 1em;
  }

  .half p.hp1 {
    margin-top: 2em;
  }

  figure h2 {
    flex-basis: 100%;
    font-size: 1.5rem;
  }

  figure h2:after {
    margin-left: 0;
  }

  figure h2 + a {
    margin: 1em auto 0 auto;
    padding: 0.5em 1em;
    width: 10em;
    text-align: center;
    display: block;
  }

  figure > div {
    margin-top: 1em;
  }

  figure > div a {
    width: 49%;
  }

  figure > div a strong {
    font-size: 1rem;
  }

  figure > div a p {
    font-size: 0.8rem;
  }

  figure > div a div {
    width: 100%;
    height: 30vw;
  }

  figure > div a img {
    max-height: 30vw;
  }

  figure a.btn {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 480px) {
  .half > div:nth-of-type(1),
  .half > div:nth-of-type(2) {
    width: 100%;
  }

  .half > div:nth-of-type(2) {
    margin-top: 1em;
  }

  .half p.hp1 {
    margin-top: 2em;
  }

  figure > div a {
    padding: 1em 0;
    width: 100%;
  }

  figure > div a + a {
    border-top: #d1d1d1 1px dashed;
  }

  figure > div a div {
    width: 100%;
    height: auto;
  }

  figure > div a img {
    max-width: 100%;
    max-height: 100vw;
  }
}
</style>
