<template>
  <div>
    <video controls :src="videoUrl">您的浏览器不支持 video 标签。</video>
  </div>
</template>

<script>
export default {
  name: "",
  props: ["videoUrl"], //视频地址
  data() {
    return {};
  },
  created() {
    console.log(this.videoUrl);
  },
  methods: {},
};
</script>

<style lang='less' scoped>
video {
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  display: block;
  // transform: translateY(4em);
}
</style>
