<template>
  <div>
    <section class="half">
      <div>
        <!-- 关于锦欣 -->
        <h2>{{ $t("indexnav.About jinxin") }}</h2>
        <p class="hp1">
          <!-- 公司介绍 -->
          {{ DataList.list[0].aboutJxContent }}
        </p>
        <a href="/aboutus/synopsis" class="btn" title="锦欣集团简介">{{
          $t("index.Learn more")
        }}</a>
      </div>
      <div>
        <img :src="DataList.list[0].aboutJxImg" alt="关于锦欣 About Jinxin" />
      </div>
    </section>
  </div>
</template>

<script>
export default {
  props: {
    DataList: {
      type: Object,
        default: () => {
          return {}
        }
    },
  },
  created() {
    console.log(this.DataList);
  },
};
</script>

<style scoped lang='less'>
.half {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.half > div:nth-of-type(1) {
  width: 50%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
}
section h2 {
  font-size: 1.6rem;
  color: #595757;
}
section h2:after {
  margin-top: 1em;
  width: 2em;
  height: 0.1em;
  content: "";
  background-color: #c81a41;
  display: block;
}
.half p {
  line-height: 2;
  width: 100%;
  color: #595757;
}

.half {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
section {
  margin: 4em auto 0 auto;
  padding: 2em 0;
  width: 100%;
  max-width: 1200px;
}
a.btn {
  margin-top: 2em;
  padding: 0.8em;
  line-height: 1;
  text-align: center;
  display: inline-block;
  border: 2px solid #19599a;
  border-radius: 2em;
  color: #595757;
}
a.btn:hover {
  color: #e60044;
  border-color: #e60044;
}
.half > div + div {
  width: 46%;
  // height: 406px;
  background-color: #fff;
  box-shadow: #ddd 0 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.half img {
  max-width: 703px;
  width: 100%;
  max-height: 482px;
  display: block;
}
@media screen and (max-width: 768px) {
  .half {
    flex-direction: column;
    margin-top: 2em;
    padding: 1em 0.5em;
    div {
      width: 100% !important;
    }
    .hp1 {
      margin-top: 2em;
      font-size: 0.8rem;
    }
    .btn {
      margin: 1em auto 0 auto;
      padding: 0.5em 1em;
      width: 10em;
      font-size: 0.8rem;
      text-align: center;
      display: block;
    }
  }
  .half > div:nth-of-type(2) {
    margin-top: 1em;
  }
}
</style>