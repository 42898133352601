<template>
  <!-- 人才招聘 -->
  <div>
    <section class="recruitment">
      <div>
        <h2>{{ $t("index.Careers") }}</h2>
        <p>{{DataList.list[0].talentRecruitment}}</p>
        <a
          href="https://app.mokahr.com/social-recruitment/fertility/39623#/"
          class="btn"
          >{{ $t("indexnav.了解招聘詳情") }}</a
        >
        <!--img src="http://www.jxr-fertility.com/skin2021/tempImg/index_08.jpg" alt="人才招聘"-->
        <!-- <video controls>
          <source
            src="http://jxr.qn.assets-cdn.cn/jixin-zhaopin-202103.m4v"
            type="video/mp4"
          />
          您的浏览器不支持 video 标签。
        </video> -->
      </div>
    </section>
  </div>
</template>

<script>
export default {
  props: {
    DataList: {
      type: Object,
      default: () => {
        return {};
      },
    },
    
  },
  created () {
    console.log(this.DataList);
    
  }
};
</script>

<style scoped lang='less'>
.recruitment h2 {
  text-align: center;
  font-size: 1.6rem;
  color: #595757;
}
section h2:after {
  margin-top: 1em;
  width: 2em;
  height: 0.1em;
  content: "";
  background-color: #c81a41;
  display: block;
}
a.btn {
  margin-top: 2em;
  padding: 0.8em;
  line-height: 1;
  text-align: center;
  display: inline-block;
  border: 2px solid #19599a;
  border-radius: 2em;
  color: #595757;
}

a.btn:hover {
  color: #e60044;
  border-color: #e60044;
}
.recruitment {
  max-width: 1903px;
  background-color: #eee;
}

.recruitment > div {
  padding-top: 4em;
  padding-bottom: 4em;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.recruitment h2 {
  text-align: center;
  font-size: 1.6rem;
  color: #595757;
}

.recruitment h2:after {
  margin: 1em auto 0 auto;
}

.recruitment p {
  color: #666;
  margin: 2em auto 0 auto;
  width: 100%;
  max-width: 800px;
  text-align: center;
  line-height: 2;
  font-size: 0.9rem;
}

.recruitment img {
  margin: 0 auto;
  width: 100%;
  max-width: 1500px;
  display: block;
  transform: translateY(4em);
}

.recruitment a.btn {
  margin: 2em auto 0 auto;
  width: 10em;
  display: block;
}

.recruitment video {
  margin: 0 auto;
  width: 100%;
  max-width: 1500px;
  display: block;
  transform: translateY(4em);
}
</style>