<template>
  <div>
    <figure>
      <figcaption>
        <!-- 新闻中心标题 -->
        <h2>{{ $t("index.News Center") }}</h2>
      </figcaption>
      <div>
        <a
          style="cursor: pointer"
          :title="item.title"
          v-for="(item, index) in DataList.slice(0, 6)"
          :key="index"
          @click="PassingParameters(item)"
        >
          <div>
            <img :src="item.img" :onerror="error" :alt="item.title" />
          </div>
          <strong>{{ item.title }}</strong>
          <span>{{ moment(item.publishDate).format("YYYY-MM-DD")}}</span>
          <p>{{ item.subTitle }}</p>
        </a>
      </div>
      <!-- 默认跳转 新闻中心 -->
      <a href="/article/list?type=1" class="btn" @click="cleanPageNum">{{
        $t("indexnav.了解更多锦欣动态")
      }}</a>
    </figure>
  </div>
</template>

<script>
import moment from "dayjs";
export default {
  data() {
    return {
      error:
        'this.src="' +
        require("../../assets/images/index/defaultNewImg.jpg") +
        '"',
    };
  },
  props: {
    DataList: {
      type: Array,
      require: false,
    },
  },
  created() {
    console.log(this.DataList);
  },
  methods: {
    moment,
    PassingParameters(item) {
      // this.$router.push("/newsinfo?id=" + id);
      if (item.outUrl != "") {
        window.location.href = item.outUrl;
      } else {
        this.$router.push("/newsinfo?id=" + item.id);
      }
    },
    cleanPageNum() {
      sessionStorage.removeItem("currentPage");
    },
  },
};
</script>

<style scoped lang='less'>
a.btn {
  margin-top: 2em;
  padding: 0.8em;
  line-height: 1;
  text-align: center;
  display: inline-block;
  border: 2px solid #19599a;
  border-radius: 2em;
  color: #595757;
}

a.btn:hover {
  color: #e60044;
  border-color: #e60044;
}
figure {
  margin: 3em auto 0 auto;
  padding: 2em 0;
  font-size: 1.2rem;
  width: 100%;
  max-width: 1200px;
}

figure figcaption {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

figure h2 {
  font-size: 1.6rem;
  color: #595757;
}

figure h2:after {
  margin: 1em auto 0 auto;
  width: 2em;
  height: 0.1em;
  content: "";
  background-color: #c81a41;
  display: block;
}

figure > div {
  margin-top: 1.5em;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

figure > div a {
  padding: 2em 0;
  width: 30%;
  font-size: 1rem;
}

figure > div a > * {
  display: block;
}

figure > div a div {
  width: 100%;
  height: 224px;
  background-color: #fefefe;
  border: #d1d1d1 1px solid;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

figure > div a strong {
  height: 1.4em;
  overflow: hidden;
  color: #595757;
  line-height: 1.4em;
  margin-top: 0.6em;
  font-weight: 900;
}

figure > div a img {
  max-width: 436px;
  max-height: 256px;
}

figure > div a p {
  margin-top: 0.8em;
  font-size: 0.85rem;
  line-height: 1.8em;
  height: 3.6em;
  overflow: hidden;
  color: #848383;
}

figure > div a span {
  // color: #aaa;
  // font-size: 0.89rem;
  font-size: 0.9em;
  line-height: 2em;
  padding-bottom: 0.4em;
  margin-bottom: 0.4em;
  color: #898989;
}

figure > div a:hover strong {
  color: #e60044;
}

figure a.btn {
  margin: 2em auto 0 auto;
  width: 10em;
  display: block;
}

figure a.btn i {
  font-size: 2em;
}
@media screen and (max-width: 1500px) {
  .half > div:nth-of-type(1) {
    width: 50%;
  }

  .half > div + div {
    height: 29vw;
  }

  section {
    margin-top: 2em;
    padding: 1em 0.5em;
    box-sizing: border-box;
  }

  figure {
    margin-top: 2em;
    padding: 1em 0.5em;
    box-sizing: border-box;
  }

  figure > div a div {
    width: 100%;
    height: 22vw;
  }

  figure > div a img {
    max-height: 22vw;
  }
}
@media screen and (max-width: 480px) {
  figure > div a {
    padding: 1em 0;
    width: 100%!important;
  }
  figure>div a img {
    max-width: 100%;
    max-height: 100vw!important;
    height: auto;
}
figure > div a div {
    width: 100%;
    height: auto;
        height: auto!important;;
}
}

@media screen and (max-width: 768px) {
  figure h2 {
    flex-basis: 100%;
    font-size: 1.5rem;
  }

  figure h2:after {
    margin-left: 0;
  }

  figure h2 + a {
    margin: 1em auto 0 auto;
    padding: 0.5em 1em;
    width: 10em;
    text-align: center;
    display: block;
  }

  figure > div {
    margin-top: 1em;
  }

  figure > div a {
    width: 49%;
  }

  figure > div a strong {
    font-size: 1rem;
  }

  figure > div a p {
    font-size: 0.8rem;
  }

  figure > div a div {
    width: 100%;
    height: 30vw;
  }

  figure > div a img {
    max-height: 30vw;
  }

  figure a.btn {
    font-size: 0.8rem;
  }
}
</style>