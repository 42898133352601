<template>
   <div class="block">
    <el-carousel height="52vw" arrow="always">
      <el-carousel-item v-for="(item,index) in imgList" :key="index">
          <a :href="item.url">
              <img :src="item.img" alt="">
          </a>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<script>
    import {getAction} from "@/api/manage";
    import { PageType } from '@/utils/constant';
    import { getAdvertiseList } from '@/utils/util'

    export default {
        data() {
            return {
                imgList: [],
                bannerList: []
            };
        },
        created() {
          // 初始化首页广告
          let that = this;
          getAction("/jeecg-system/common/frontend/advertise/active_list", {typeCode: PageType.INDEX}).then(res => {
            that.imgList = getAdvertiseList(res.result.records, this.$store.state.nowlanguage);
            that.bannerList = res.result.records;
          });
        },
      computed:{
          getVuexData(){
            return this.$store.state.nowlanguage;
          }
      },
      watch: {
        getVuexData: function(newValue, oldValue) {
          this.imgList = getAdvertiseList(this.bannerList, this.$store.state.nowlanguage);
        }
      }
    };
</script>
<style lang=less scoped>
.block{
  width: 100%;
  /* height: 80%; */
  img{
    width: 100%;
    height:100%
  }
}
/deep/ .is-active{
 color: #19599a;
}
ul /deep/ button{
  background-color: #000;
}

/deep/ .el-carousel__button {
  /* // 指示器按钮 */
  padding: 0 25px;
        background-color: #19599a

}
</style>
